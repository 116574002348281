import ClipboardJS from "clipboard";
// @ts-ignore-next-line
import Toastify from 'toastify-js';
import { ViewHook } from "phoenix_live_view";

const CopyToClipboard = {
  init() {
    const clipboard = new ClipboardJS(this.el);
    clipboard.on('success', () => {
      const successMsg = this.el.dataset.successMsg || "Copied to clipboard";
      Toastify({
        text: successMsg,
        close: true,
        className: "success",
      }).showToast();

    });
  },
  mounted() {
    this.init();
  }
} as ViewHook & any;

export default CopyToClipboard;