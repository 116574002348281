import { ViewHook } from "phoenix_live_view";
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'

const RichTextEditor = {
  mounted() {
    const editorDiv = this.el.querySelector(":scope > .event-rich-description")
    const inputEl = this.el.querySelector(":scope > input[type=hidden]")

    const actionBtns: NodeList = this.el.querySelectorAll(".menu-bar button.action")
    const buttons = (Array.from(actionBtns) as any[])
      .map((button: HTMLButtonElement & { config: any }) => {
        const configStr = button.getAttribute("data-config") || "{}"
        const config = JSON.parse(configStr)
        button.config = config
        return button
      })

    buttons.forEach((button: HTMLButtonElement & { config: any }) => {
      const params = button.config.actionParams || []

      button.addEventListener("click", (e: MouseEvent) => {
        this.editor.chain().focus()[button.config.action](...params).run();
        e.preventDefault();
      });
    });

    const updateButtonStates = (editor: Editor) => {
      buttons.forEach((button: HTMLButtonElement & { config: any }) => {
        const params = button.config.actionParams || []
        button.classList.toggle("enabled", editor.isActive(button.config.activeCheck, ...params));
      });
    };

    this.editor = new Editor({
      element: editorDiv,
      extensions: [StarterKit],
      content: inputEl.value,
      onUpdate({ editor }) {
        inputEl.value = editor.getHTML()
        updateButtonStates(editor)
      },
      onSelectionUpdate({ editor }) {
        updateButtonStates(editor)
      },
    })
  },

  destroyed() {
    if (!this.editor) {
      return;
    }
    this.editor.destroy()
  }
} as ViewHook & any;

export default RichTextEditor;