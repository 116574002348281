import { ViewHook } from "phoenix_live_view";

const PPPasswordInput = {
  onShowChanged(e: InputEvent) {
    if ((<HTMLInputElement>e.target).checked) {
      this.passwordInput.type = "text";
      this.passwordInput.setAttribute("autocomplete", "off");
    } else {
      this.passwordInput.type = "password";
      this.passwordInput.setAttribute("autocomplete", this.originalAutocomplete);
    }
  },
  mounted() {
    this.showPasswordCheckbox = this.el.getElementsByClassName("show-password-chk")[0];
    if (!this.showPasswordCheckbox) {
      return;
    }
    this.passwordInput = this.el.querySelector("input[type='password']");
    this.originalAutocomplete = this.passwordInput.getAttribute("autocomplete")
    this.onShowChanged = this.onShowChanged.bind(this);
    this.showPasswordCheckbox.addEventListener("change", this.onShowChanged);
  },
  destroyed() {
    if (!this.showPasswordCheckbox) {
      return;
    }
    this.showPasswordCheckbox.removeEventListener("change", this.onShowChanged);
  }
} as ViewHook & any;

export default PPPasswordInput;