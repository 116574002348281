import Leaflet from 'leaflet'
import type { LeafletMouseEvent } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import { ViewHook } from 'phoenix_live_view'

const LeafletLocationInput = {
  updateInputs(latlng: { lat: number, lng: number }) {
    if (this.lngInput) {
      this.lngInput.value = latlng.lng
    }
    if (this.latInput) {
      this.latInput.value = latlng.lat
    }
  },

  addMarker(latlng: { lat: number, lng: number }) {

    if (this.marker) {
      return
    }

    this.marker = Leaflet.marker(
      latlng,
      {
        draggable: !this.config.readonly,
        autoPan: true,
        icon: new Icon({
          iconUrl: markerIconPng,
          iconSize: [25, 41],
          iconAnchor: [12, 41]
        }),
      });


      if (!this.config.readonly) {
        const deleteButton = document.createElement("button")
        deleteButton.textContent = this.config.remove_marker_text
        deleteButton.type = "button"
        deleteButton.addEventListener("click", () => {
          this.marker.remove()
          this.updateInputs({ lat: null, lng: null })
          this.marker = null
        })
        const popup = Leaflet.popup().setContent(deleteButton)
        this.marker.bindPopup(popup)
      }

    if (!this.config.readonly) {
      this.marker.on('move', ({ latlng }: Event & { latlng: any }) => {
        this.updateInputs(latlng)
      })
    }
    this.updateInputs(latlng)
    this.marker.addTo(this.map)

  },

  mounted() {
    const configStr = this.el.getAttribute("data-config") || "{}"
    this.config = JSON.parse(configStr)
    this.config.markers = this.config.markers || []
    this.config.remove_marker_text =  this.config.remove_marker_text || "Remove marker"

    let lat: number = NaN
    let lng: number = NaN
    this.latInput = this.el.querySelector("[name*='latitude']")
    if (this.latInput && this.latInput.value) {
      lat = Number(this.latInput.value)
    }
    this.lngInput = this.el.querySelector("[name*='longitude']")
    if (this.lngInput && this.lngInput.value) {
      lng = Number(this.lngInput.value)
    }

    if (!isNaN(lat) && !isNaN(lng)) {
      const marker = this.config.markers[0] || {}
      marker.lat = lat
      marker.lng = lng
      this.config.markers[0] = marker
    }
    this.mapHolder = this.el.querySelector(".map-holder")
    this.map = Leaflet.map(this.mapHolder).setView([45.833100, 16.010171], 13);
    Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    for (let i = 0; i < this.config.markers.length; i++) {
      this.addMarker(this.config.markers[i])
      this.map.setView(this.config.markers[i], 13)
    }

    if (!this.config.readonly) {
      this.map.on('click', (e: LeafletMouseEvent) => {
        this.addMarker(e.latlng)
      });
    }
  }
} as ViewHook & any;

export default LeafletLocationInput;