import { ViewHook } from "phoenix_live_view";

const PopupButton = {
  actionButtonClick(e: Event) {
    console.log('click, shown:', this.targetEl.dataset.shown);
    e.stopPropagation();
    if (this.targetEl.dataset.shown == 'true') {
      this.hideTarget();
      return;
    }
    this.showTarget();
  },

  documentClick(e: MouseEvent) {
    if (!e.target) {
      return;
    }

    if (!this.targetEl.contains(e.target as Node)) {
      this.hideTarget();
    }
  },

  positionTargetInViewport() {
    this.targetEl.style.position = 'absolute'
    this.targetEl.style.left = '0';
    
    const boundingRect = this.targetEl.getBoundingClientRect();

    if (boundingRect.right > window.innerWidth) {
      this.targetEl.style.left = `${window.innerWidth - boundingRect.right}px`
    }
  },

  showTarget() {
    this.targetEl.classList.add('visible');
    this.targetEl.classList.remove('collapse');
    this.positionTargetInViewport();
    this.targetEl.dataset.shown = 'true';
    const input = this.targetEl.querySelector('input');
    if (input) {
      input.focus();
    }
  },

  hideTarget() {
    this.positionTargetInViewport();
    this.targetEl.classList.remove('visible');
    this.targetEl.classList.add('collapse');
    this.targetEl.dataset.shown = 'false';
  },

  mounted() {
    console.log('mounted');
    this.actionButton = this.el as HTMLButtonElement;
    this.popupSelector = this.actionButton.dataset.popup!

    const targetEl = document.querySelector(this.popupSelector)
    if (!targetEl) {
      console.error(`PopupButton: Target element ${this.popupSelector} could not be found`)
      return;
    }
    this.targetEl = <HTMLElement>targetEl;

    this.showTarget = this.showTarget.bind(this);
    this.hideTarget = this.hideTarget.bind(this);
    this.actionButtonClick = this.actionButtonClick.bind(this);
    this.documentClick = this.documentClick.bind(this);
    this.positionTargetInViewport = this.positionTargetInViewport.bind(this);

    this.positionTargetInViewport()

    this.actionButton.addEventListener('click', this.actionButtonClick);
    document.addEventListener('click', this.documentClick);
  },

  beforeUpdate() {
    console.log('before update')
  },


  destroyed() {
    console.log('destroyed')
    document.removeEventListener('click', this.documentClick);
    this.actionButton.removeEventListener('click', this.actionButtonClick)
  }
} as ViewHook & {
  actionButton: HTMLButtonElement;
  popupSelector: string;
  targetEl: HTMLElement;

  actionButtonClick: (e: Event) => void;
  documentClick: (e: MouseEvent) => void;

  positionTargetInViewport: () => void;
  showTarget: () => void;
  hideTarget: () => void;
};

export { PopupButton };
