import { ViewHook } from "phoenix_live_view";

const TokenInput = {
  handleInput(_e: KeyboardEvent) {
    this.pushEventTo(this.el, this.config.change_event, { value: this.el.value }, (reply: any) => {
      if (reply.clear) {
        this.el.value = ""
      }
    })
  },

  mounted() {
    console.log('token input mounted')
    this.config = JSON.parse(this.el.dataset.config);
    // Keeping `this`
    this.handleInput = this.handleInput.bind(this);
    this.el.addEventListener("input", this.handleInput);
  },

  destroyed() {
    this.el.removeEventListener("input", this.handleInput);
  }
} as ViewHook & any;

export default TokenInput;