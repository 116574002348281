import { ViewHook } from "phoenix_live_view";

const ComboboxInput = {
  handleKeyup(e: KeyboardEvent) {
    if (e.key != "Enter") {
      return true;
    }
    const { add_event, search_field_name } = this.config;
    const input: HTMLInputElement = this.el.querySelector(`[name="${search_field_name}"]`)
    this.pushEventTo(this.el, add_event, input.value)

    e.preventDefault()
    return false;
  },
  mounted() {
    this.config = JSON.parse(this.el?.dataset?.config);
    this.handleKeyup = this.handleKeyup.bind(this);
    this.el.addEventListener("keyup", this.handleKeyup);
  },
  destroyed() {
    this.el.removeEventListener("keyup", this.handleKeyup);
  }
} as ViewHook & any;

export default ComboboxInput;