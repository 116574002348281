import { ViewHook } from "phoenix_live_view";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// PROD
// const firebasePubKey = "BH7L1JZma9-MO5fO4CdBCRE5hz2t6BtKhcD57qSmJ3X6Ode_l981QHiw8PSnBwtKeycpF9z6O4MVGM5M9sjXdZc";
// const firebaseConfig = {
//   apiKey: "AIzaSyDzfvzGJItVk44r1iSn9fJrTeV9_kyEW1k",
//   authDomain: "papageno-384220.firebaseapp.com",
//   projectId: "papageno-384220",
//   storageBucket: "papageno-384220.appspot.com",
//   messagingSenderId: "792735951556",
//   appId: "1:792735951556:web:04110ceb8d013970153791",
// };

declare global {
  interface Window {
    firebasePubKey?: string;
    firebaseConfig?: any;
  }
}


// Initialize Firebase
initializeApp(window.firebaseConfig);
const messaging = getMessaging();

const getFCMToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: window.firebasePubKey,
      serviceWorkerRegistration: window.swReg,
    });
    return token;
  } catch (e) {
    console.log("getFCMToken error:", e);
    return undefined;
  }
};

onMessage(messaging, (payload) => {
  console.log("message received:", payload);
});

const subscribe = async () => {
  const fcmToken = await getFCMToken();

  return fcmToken;
}

const unsubscribe = async () => {
  const subscription = await getSubscription()
  return subscription?.unsubscribe()
}

const getSubscription = async () => {
  return await window.swReg?.pushManager.getSubscription();
}

const isSubscribed = async () => {
  const sub = await getSubscription()
  return sub != null
}

export const EnableNotificationsPopup = {
  notificationsSupported() {
    return "Notification" in window;
  },

  showPopup() {
    this.el.classList.remove("hidden");
  },

  hidePopup() {
    this.el.classList.add("hidden");
  },

  permissionUndecided() {
    return Notification.permission == "default";
  },

  mounted() {
    this.enableBtn = this.el.querySelector("button[data-action=enable]");
    this.ignoreBtn = this.el.querySelector("button[data-action=ignore]");

    // this.requestPermission = this.requestPermission.bind(this);

    if (this.notificationsSupported() && this.permissionUndecided()) {
      this.showPopup();

      // this.enableBtn.addEventListener("click", this.requestPermission);
    }
  },
} as ViewHook & any;

export const NotificationSettingsButtons = {
  setLoading(loading: boolean) {
    this.enableBtn.disabled = loading;
    this.disableBtn.disabled = loading;
  },

  get notificationsSupported(): boolean {
    return "Notification" in window;
  },

  async onEnableClick() {
    try {
      this.setLoading(true);
      const token = await subscribe();
      this.updateUI();
      // console.log('subscribed, token:', token)
      this.pushEvent("subscribe_to_push", { token })
    } catch (e) {
      console.warn("error subscribing:", e)
    } finally {
      this.setLoading(false);
    }
  },

  async onDisableClick() {
    await unsubscribe()
  },

  async updateUI() {
    if (this.notificationsSupported) {
      this.notSupportedMsg.remove();
    } else {
      this.enableBtn.remove();
      this.disableBtn.remove();
      this.notSupportedMsg.classList.remove('hidden')
    }

    const subscribed = await isSubscribed();

    // console.log('subscribed:', subscribed);
    if (subscribed) {
      this.enableBtn.classList.add("hidden")
      this.disableBtn.classList.remove("hidden")
    } else {
      this.enableBtn.classList.remove("hidden")
      this.disableBtn.classList.add("hidden")
    }
  },

  mounted() {
    // console.log("mounted:", this.el);

    this.notSupportedMsg = this.el.querySelector('.not-supported-message')!;
    this.enableBtn = this.el.querySelector(
      "[data-enable-notifications=enable]"
    )!;
    this.disableBtn = this.el.querySelector(
      "[data-enable-notifications=disable]"
    )!;

    this.onEnableClick = this.onEnableClick.bind(this);
    this.onDisableClick = this.onDisableClick.bind(this);

    this.enableBtn.addEventListener('click', this.onEnableClick);
    this.enableBtn.addEventListener('click', this.onDisableClick);

    this.updateUI()
  },

  destroyed() {
    this.disableBtn.removeEventListener('click', this.onDisableClick);
    this.enableBtn.removeEventListener('click', this.onEnableClick);
  },
} as ViewHook & {
  notSupportedMsg: HTMLParagraphElement;
  enableBtn: HTMLButtonElement;
  disableBtn: HTMLButtonElement;

  notificationsSupported: boolean;
  onEnableClick: () => Promise<void>;
  onDisableClick: () => Promise<void>;
  updateUI: () => Promise<void>;
  setLoading: (loading: boolean) => void;
};
