import { DateTime } from 'luxon';
import { ViewHook } from 'phoenix_live_view';
import flatpickr from "flatpickr";
import "flatpickr/dist/l10n/hr";

import Instance = flatpickr.Instance;


const DateTimeInput = {
  mounted() {
    // @ts-ignore-next-line
    this.el.pickr = flatpickrInit(this.el)
  },
  destroyed() {
    // @ts-ignore-next-line
    if (this.el.pickr.destroy) {
      // @ts-ignore-next-line
      this.el.pickr.destroy()
    }
  }
} as ViewHook;

const timezone = 'Europe/Zagreb'
const locale = 'hr'

const flatpickrInit = (element: HTMLElement) => {
  let config: { [k: string]: any }
  try {
    const configStr = element.getAttribute("data-flatpickr-config") || "{}"
    config = JSON.parse(configStr)
    const format = config.enableTime ? DateTime.DATETIME_MED_WITH_WEEKDAY : DateTime.DATE_MED_WITH_WEEKDAY
    config = {
      ...config,
      wrap: true,
      altInput: true,
      disableMobile: true,
      formatDate: function (d: Date) {
        const dt = DateTime.fromJSDate(d).setLocale(locale).setZone(timezone);
        return config.enableTime ? dt.toISO() : dt.toISODate()
      },
      formatAltDate: function (d: Date) {
        return DateTime.fromJSDate(d).setLocale(locale).setZone(timezone).toLocaleString(format)
      },
    }
  } catch (e) {
    config = {}
  }
  return flatpickr(element, config);
}

export const onBeforeElUpdatedCb = (from: HTMLElement, to: HTMLElement): boolean | undefined => {
  // Probably half-broken but it seems to work.
  // @ts-ignore-next-line
  if (from.pickr && from.id == to.id) {
    const pickr: Instance = (<{ pickr: Instance }><unknown>from).pickr;
    const toInput = to.querySelector('input')
    if (!toInput) {
      return true
    }
    pickr.setDate(toInput.value)
  }
  return;
}


export default DateTimeInput;