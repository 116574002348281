import { ViewHook } from "phoenix_live_view";
import Sortable from 'sortablejs';

const SortableHook = {
  mounted() {
    new Sortable(this.el, {
      animation: 150,
      delay: 100,
      dragClass: 'drag-item',
      ghostClass: 'drag-ghost',
      forceFallback: true,
      onEnd: e => {
        const params = { old: e.oldIndex, new: e.newIndex, ...e.item.dataset };
        this.pushEventTo(this.el, "reposition", params)
      }
    })
  },
} as ViewHook & {};

export {
  SortableHook,
}